import "./videos.css";
import slideLeft from "./imgs/slide-left.svg";
import slideRight from "./imgs/slide-right.svg";
import videoSrc from "./data";
import { useState } from "react";

const Videos = () => {
  const [current, setCurrent] = useState(0);
  const length = videoSrc.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(videoSrc) || videoSrc.length < 0) {
    return null;
  }
  const moveDot = (index) => {
    setCurrent(index);
  };

  return (
    <>
      <div className="carousel">
        {videoSrc.map((videos, index) => {
          return (
            <div
              key={videos.id}
              className={index === current ? "animate true" : "animate"}
            >
              {index === current && (
                <video controls>
                  <source src={videos.src} />
                </video>
              )}
            </div>
          );
        })}
      </div>
      <div className="slides">
        <img onClick={prevSlide} src={slideLeft} alt="slide" />
        <div className="active-video">
          {Array.from({ length: length }).map((data, index) => (
            <button
              key={index}
              onClick={() => moveDot(index + 0)}
              className={current === index + 0 ? "active-dot" : ""}
            ></button>
          ))}
        </div>
        <img onClick={nextSlide} src={slideRight} alt="slide" />
      </div>
    </>
  );
};

export default Videos;
