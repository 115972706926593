import "./footer.css";
import phoneicon from "./footer-imgs/phone-icon.svg";
import emailicon from "./footer-imgs/email-icon.svg";
import clockicon from "./footer-imgs/clock-icon.svg";
// import twitterIcon from "./footer-imgs/twitter-icon.svg";
import instagramIcon from "./footer-imgs/instagram-icon.svg";
// import facebookIcon from "./footer-imgs/facebook-icon.svg";
import whatsappIcon from "./footer-imgs/whatsapp-icon.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <nav>
        <div className="footer__contact">
          <a href="tel:+2349160000381">
            <img src={phoneicon} alt="phoneicon" />
            <p>
              +2349160000381
              <br />
              <span>Call us Now</span>
            </p>
          </a>
          <a href="mailto:admin@kingtrustmedical.com">
            <img src={emailicon} alt="phoneicon" />
            <p>
              admin@kingtrustmedical.com
              <br />
              <span>Drop us an email</span>
            </p>
          </a>
          <a href="/">
            <img src={clockicon} alt="phoneicon" />
            <p>
              24 X 7 support
              <br />
              <span>We are open on</span>
            </p>
          </a>
        </div>
        <div className="footer__quick__links">
          <ul>
            <h3>About Us</h3>
            <li>
              <Link to="/booking">Find A Doctor</Link>
            </li>
            <li>
              <Link to="/services">Medical Services</Link>
            </li>
            <li>
              <Link>Patient Testimonials</Link>
            </li>
          </ul>
          <ul>
            <h3>Our Services</h3>
            <li>
              <Link>General Medicine</Link>
            </li>
            <li>
              <Link>Orthopaedic Surgery</Link>
            </li>
            <li>
              <Link>Antenatal screening</Link>
            </li>
          </ul>
          <ul>
            <h3>General Services</h3>
            <li>
              <Link>Malaria Parasite</Link>
            </li>
            <li>
              <Link>Blood Group</Link>
            </li>
            <li>
              <Link>Urinalysis</Link>
            </li>
          </ul>
          <ul>
            <h3>Hospitals</h3>
            <li>
              <Link>Contact Us</Link>
            </li>
            <li>
              <Link>HMO</Link>
            </li>
            <ul className="footer__social__links">
              {/* <li>
                <Link aria-label="facebook">
                  <img src={facebookIcon} alt="our facebook" />
                </Link>
              </li> */}
              {/* <li>
                <Link aria-label="twitter">
                  <img src={twitterIcon} alt="our twitter" />
                </Link>
              </li> */}
              <li>
                <a
                  href="https://www.instagram.com/kingtrustmedicals/"
                  target="_blank"
                  aria-label="instagram" rel="noreferrer"
                >
                  <img src={instagramIcon} alt="our instagram" />
                </a>
              </li>
              <li>
                <a href="tel:+2349160000382">
                  <img src={whatsappIcon} alt="our whatsapp" />
                </a>
              </li>
            </ul>
          </ul>
        </div>
        <div className="footer__policy">
          <ul>
            <li>
              <Link
                style={{
                  borderRight: "1px solid hsl(231, 51%, 33%)",
                  padding: "0 .5rem",
                }}
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                style={{
                  borderRight: "1px solid hsl(231, 51%, 33%)",
                  padding: "0 .5rem",
                }}
              >
                Disclaimer
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                style={{
                  borderRight: "1px solid hsl(231, 51%, 33%)",
                  padding: "0 .5rem",
                }}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link style={{ padding: "0 .5rem" }} className="last-child">
                Sitemap
              </Link>
            </li>
          </ul>
          <p>© {currentYear} Kingtrust. All Rights Reserved.</p>
        </div>
      </nav>
    </footer>
  );
};
export default Footer;
