import { useState } from "react";
// import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Slider from "react-slick";
import slideLeft from "../videos/imgs/slide-left.svg";
import slideRight from "../videos/imgs/slide-right.svg";
import "./vid.css";
const images = [
  "https://res.cloudinary.com/dbz1rgohb/video/upload/v1722287181/kingtrust/Interview2_podkkn.mp4",
  "https://res.cloudinary.com/dbz1rgohb/video/upload/v1722287159/kingtrust/Interview1_hu513b.mp4",
  "https://res.cloudinary.com/dbz1rgohb/video/upload/v1722287125/kingtrust/Interview1_xcdht2.mp4",
  "https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797950/KingTrrustTestiomonial33_y1tymy.mp4",
  "https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797907/KingTrrustTestiomonial2_naoqq6.mp4",
  "https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797845/KingTrrustTestiomonial4_jhbk8q.mp4",
  "https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797416/KingTrrustTestiomonial1_ifhr0g.mp4",
];

// const length = images.length;
function Slick() {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="reveal nexts" onClick={onClick}>
        <img src={slideRight} alt="" />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="reveal prevs" onClick={onClick}>
        <img src={slideLeft} alt="" />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  // const moveDot = (index) => {
  //   setImageIndex(index);
  // };

  return (
    <div className="testifiers">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <video
            key="id"
            controls
            className={idx === imageIndex ? "test activeTest" : "test"}
          >
            <source src={img} />
          </video>
        ))}
      </Slider>
      {/* <div className="active-vid">
        {Array.from({ length: length }).map((data, index) => (
          <button key={index} onClick={() => moveDot(index + 0)}></button>
        ))}
      </div> */}
    </div>
  );
}

export default Slick;
