// import logo from './imgs/logo.svg';
import bglayer from './imgs/intro-fade-bg.svg';

const slideData = [
    {
        url: 'https://res.cloudinary.com/dqst5wwrv/image/upload/v1672349492/kingtrust/IMG-20221229-WA0036_jdyujt.jpg',
        // logo: logo,
        heading: 'Welcome to kingtrust Medical Center',
        paragraph: 'A foremost healthcare center with the best health services',
        button: 'Book an Appointment',
        fadebg: bglayer,
    },
    {
        url: 'https://res.cloudinary.com/dqst5wwrv/image/upload/v1672349501/kingtrust/IMG-20221229-WA0043_huldn4.jpg',
        // logo: logo,
        heading: 'Welcome to kingtrust Medical Center',
        paragraph: 'Standard medical specialist, doctors nurses and caregivers',
        button: 'Book an Appointment',
        fadebg: bglayer,
    },
    {
        url: 'https://res.cloudinary.com/dqst5wwrv/image/upload/v1672349498/kingtrust/IMG-20221229-WA0045_qwsn0a.jpg',
        // logo: logo,
        heading: 'Welcome to kingtrust Medical Center',
        paragraph: 'A Center you can call home',
        button: 'Book an Appointment',
        fadebg: bglayer,
    },
    // {
    //     url: 'https://res.cloudinary.com/dqst5wwrv/image/upload/v1672828968/booking-intro-img_udgb1t.png',
    //     // logo: logo,
    //     heading: 'Welcome to kingtrust Medical Center',
    //     paragraph: 'A foremost healthcare center with the best health services',
    //     button: 'Book an Appointment',
    //     fadebg: bglayer,
    // },
    // {
    //     url: 'https://res.cloudinary.com/dqst5wwrv/image/upload/v1672349489/kingtrust/IMG-20221229-WA0035_duubqy.jpg',
    //     // logo: logo,
    //     heading: 'Welcome to kingtrust Medical Center',
    //     paragraph: 'Standard medical specialist, doctors nurses and caregivers',
    //     button: 'Book an Appointment',
    //     fadebg: bglayer,
    // },
    {
        url: 'https://res.cloudinary.com/dqst5wwrv/image/upload/v1673646244/kingtrust/iStock-815517604_bteksy.jpg',
        // logo: logo,
        heading: 'Welcome to kingtrust Medical Center',
        paragraph: 'A Center you can call home',
        button: 'Book an Appointment',
        fadebg: bglayer,
    },
    {
        url: 'https://res.cloudinary.com/dqst5wwrv/image/upload/v1673646246/kingtrust/levi-meir-clancy-QBpxh4jS_d0-unsplash_mre6b4.jpg',
        // logo: logo,
        heading: 'Welcome to kingtrust Medical Center',
        paragraph: 'A foremost healthcare center with the best health services',
        button: 'Book an Appointment',
        fadebg: bglayer,
    },
]
export default slideData;