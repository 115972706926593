import { useState, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const Form = () => {
  const [selected, setSelected] = useState(null);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_gyd5arf",
        "template_bs2vewd",
        form.current,
        "AkG_u8mKyeRu67gQC"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Booking Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (error) => {
          console.log(error.text);
          toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
    e.target.reset();
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <input type="text" placeholder="Name" name="user_name" required />
      <br />
      <input type="text" placeholder="Email" name="user_email" required />
      <br />
      <input type="text" name="number" placeholder="Phone Number" required />
      <br />
      <ReactDatePicker
        onChange={(date) => setSelected(date)}
        selected={selected}
        dateFormat="dd/MM/yyyy"
        placeholderText={"Date"}
        name="date"
        required
      />
      <br />
      <label htmlFor="time">Time</label>
      <br />
      <input
        className="time"
        type="time"
        placeholder="Time"
        name="time"
        required
      />
      <br />
      <select name="service" required>
        <option value="none" selected disabled>
          Select Specialty
        </option>
        <option value="General Medicine">General Medicine</option>
        <option value="Cervical screening">Cervical screening</option>
        <option value="Orthopaedic Surgery">Orthopaedic Surgery</option>
        <option value="EnT Surgery">EnT Surgery</option>
        <option value="Paediatrician Care">Paediatrician Care</option>
        <option value="Antenatal screening">Antenatal screening</option>
        <option value="Fertility treatment">Fertility treatment</option>
        <option value="Laboratory services">Laboratory services</option>
        <option value="Well Being Clinic">Well Being Clinic</option>
        <option value="Dermatology">Dermatology</option>
        <option value="Respiratology">Respiratology</option>
        <option value="Rheumatology">Rheumatology</option>
        <option value="Hematology">Hematology</option>
      </select>
      <select name="type" required>
        <option value="none" selected disabled>
          Type of Visit
        </option>
        <option value="walk in">Walk In</option>
        <option value="online">Online</option>
      </select>
      <br />
      <button type="submit" className="appiontment-submit">
        BOOK NOW
      </button>
    </form>
  );
};

export default Form;
