import { NavLink, Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import "./navbar.css";
import covidlogo from "./imgs/covid-logo.svg";
import logo from "./imgs/Kingtrust.png";

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowLinks(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  return (
    <header>
      <nav ref={menuRef} className="header__nav">
        <Link>
          <img className="header__logo" src={logo} alt="kingtrust logo" />
        </Link>
        <div
          onClick={() => setShowLinks(!showLinks)}
          className="header__links__toggler"
        >
          <span className={showLinks ? "rf" : ""}></span>
          <span className={showLinks ? "dn" : ""}></span>
          <span className={showLinks ? "rb" : ""}></span>
        </div>
        <div className={showLinks ? "header__links show" : "header__links"}>
          <ul className="pages__links">
            <li onClick={() => setShowLinks(!showLinks)}>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/"
              >
                HOME
              </NavLink>
            </li>
            <li onClick={() => setShowLinks(!showLinks)}>
              <NavLink to="/about">ABOUT</NavLink>
            </li>
            <li onClick={() => setShowLinks(!showLinks)}>
              <NavLink to="/services">SERVICES</NavLink>
            </li>
            <li onClick={() => setShowLinks(!showLinks)}>
              <NavLink to="/contact">CONTACT US</NavLink>
            </li>
            {/* <li onClick={() => setShowLinks(!showLinks)}>
              <NavLink to="/blog">BLOG</NavLink>
            </li> */}
          </ul>
          <ul className="header__btn__links">
            <li onClick={() => setShowLinks(!showLinks)}>
              <Link to="/booking" className="header__appointment__btn">
                Book an Appointment
              </Link>
            </li>
            <li onClick={() => setShowLinks(!showLinks)}>
              <Link to="/covid19" className="header__covid__btn">
                <img src={covidlogo} alt="/" />
                Covid-19 Testing
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
export default Navbar;
