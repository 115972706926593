import "./home.css";
import vision from "./home-images/vision.svg";
import mision from "./home-images/mision.svg";
import medicineLogo from "./home-images/medicine-logo.svg";
import surgery from "./home-images/surgery.svg";
import treatmentLogo from "./home-images/treatment.svg";
import laboratory from "./home-images/laboratory.svg";
import cardiology from "./home-images/cardiology.svg";
import Slider from "./slider/slider";
import missionBg from "./home-images/mission-bg.svg";
import visionbgB from "./home-images/mission-bg2.svg";
import bgborder from "./home-images/bg-border.svg";
import Videos from "./videos/videos";
import { Link } from "react-router-dom";
import Slick from "./vids/vid";
import Form from "../form/form";
import SEO from "../SEO/meta";

const Home = () => {
  return (
    <>
      <SEO
        title="Home - Kingtrust"
        description="Customer-centric healthcare is at the heart of everything we do at
            Kingtrust Medical Center. We also provide a full range of healthcare
            services that meet your needs, including 24/7 emergency care,
            diagnostics care, treatment and rehabilitation, and long-term
            support."
      />
      <div className="home">
        <Slider />
        <section className="home__priority">
          <img src="https://res-console.cloudinary.com/dqst5wwrv/thumbnails/v1/image/upload/v1712078812/a2luZ3RydXN0LTFfdWRsaWRx/preview" alt="/" className="priority-bg1" />
          <img src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1712079187/Kingtrust-2_nfg6s4.svg" alt="/" className="priority-bg2" />
          <img src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1712079187/Kingtrust-2_nfg6s4.svg" alt="/" className="priority-bg3" />
          <h2>
            Healthcare is our <span>priority</span>
          </h2>
          <p>
            Customer-centric healthcare is at the heart of everything we do at
            Kingtrust Medical Center. We also provide a full range of healthcare
            services that meet your needs, including 24/7 emergency care,
            diagnostics care, treatment and rehabilitation, and long-term
            support.
          </p>
        </section>
        <section className="home__hospital__welcome">
          <div>
            <h2>
              Welcome to
              <span> kingtrust Medical Center</span>
            </h2>
            <p>
              We are Kingtrust Medical Center. We provide affordable, quality
              and top-notch healthcare services to all patients. We believe that
              everyone deserves the best healthcare, and we work hard every day
              to bring it to you.
            </p>
            {/* <p>
              Our goal is to provide affordable and quality health services.
              Every patient deserves a better chance at good health and
              Kingtrust Hospital will be there for them.
            </p> */}
            <Link to="/about">
              <button>Read More</button>
            </Link>
          </div>
          <figure>
            <img
              src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1672350850/WhatsApp_Image_2022-12-29_at_22.01.05_cfmank.jpg"
              alt="female doctor posing"
            />
          </figure>
          <img
            src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1712079339/kingtrust-3_tez0fa.svg"
            alt="/"
            className="home__hospital__welcome-bg1"
          />
        </section>
        <section className="home__goals">
          <div className="home__goals__vision">
            <img
              src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1672349478/kingtrust/IMG-20221229-WA0024_-_Copy_gzmxwx.jpg"
              alt="team-young-specialist-doctors-standing-corridor-hospital 1"
            />
            <div style={{ textAlign: "left" }}>
              <img src={visionbgB} alt="bg" className="vision-bg" />
              <img src={bgborder} alt="bg" className="vision-bg-border" />
              <img src={vision} alt="our vision" />
              <h3>Our Vision</h3>
              <p>
                Our Vision is to be the unmatched leader in providing unparalled
                health delivery in Nigeria.
              </p>
            </div>
          </div>
          <div className="home__goals__mission">
            <div style={{ textAlign: "right" }}>
              <img src={mision} alt="#" />
              <img src={missionBg} alt="bg" className="mission-bg" />
              <h3>Our Mission</h3>
              <p>
                Our Mission is to provide affordable, quality and top-notch
                healthcare services to all patient.
              </p>
            </div>
            <img
              src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1672351193/IMG-20221229-WA0032_wqvock.jpg"
              alt="our hospital"
            />
          </div>
        </section>
        <section className="home__services">
          <img src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1712079628/services-bg_op6jwg.svg" alt="services" className="services__bg" />
          <h2>Our services</h2>
          <p>We offer different Services to improve your health. </p>
          <div className="home__service__type">
            <div className="service__types">
              <img src={medicineLogo} alt="medicineLogo" />
              <h4>General Medicine</h4>
            </div>
            <div className="service__types">
              <img src={surgery} alt="surgery" />
              <h4>EnT Surgery</h4>
            </div>
            <div className="service__types">
              <img src={treatmentLogo} alt="treatmentLogo" />
              <h4>Fertility treatment</h4>
            </div>
            <div className="service__types">
              <img src={laboratory} alt="laboratory" />
              <h4>Laboratory serviceS</h4>
            </div>
            <div className="service__types">
              <img src={cardiology} alt="cardiolog" />
              <h4>Cardiology</h4>
            </div>
          </div>
        </section>
        <section className="home__appiontment">
          <div className="img-cover">
            <img
              src="https://res.cloudinary.com/dqst5wwrv/image/upload/v1672351349/IMG-20221229-WA0038_a8ntc0.jpg"
              alt="appiontment"
            />
          </div>
          <div className="div">
            <h2>
              Book <span>Appointment</span>
            </h2>
            <p>
              To reach out to Kingtrust Medical Center Team, please fill in the
              below form. Our team members will revert back to you shortly.
            </p>
            <Form />
          </div>
        </section>
        <section className="home__testimony">
          <h2>TESTIMONIAL</h2>
          <Videos />
          <Slick />
        </section>
      </div>
    </>
  );
};

export default Home;
