import { Helmet } from "react-helmet-async";

export default function SEO(props) {
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            {/* End standard metadata tags */}

            {/* Facebook tags */}
            <meta property="og:type" content="article" />
            <meta property="og:title" content={props.title} />
            {/* <meta property="og:image" content={imageURL} /> */}
            <meta property="og:url" content="https://www.doshservices.com/" />
            <meta property="og:description" content={props.description} />
            {/* End Facebook tags */}

            {/* Twitter tags */}
            <meta name="twitter:creator" content="@doshservices" />
            <meta name="twitter:card" content="article" />
            <meta name="twitter:title" content={props.title} />
            <meta name="twitter:description" content="Your One Stop Tech Solution." />
            {/* <meta name="twitter:image" content={imageURL} /> */}
            {/* End Twitter tags */}
        </Helmet>
    );
}
