const videoSrc = [
    {
        id: 1,
        src: 'https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797950/KingTrrustTestiomonial33_y1tymy.mp4'
    },
    {
        id: 2,
        src: 'https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797907/KingTrrustTestiomonial2_naoqq6.mp4'
    },
    {
        id: 3,
        src: 'https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797845/KingTrrustTestiomonial4_jhbk8q.mp4'
    },
    {
        id: 4,
        src: 'https://res.cloudinary.com/dqst5wwrv/video/upload/v1671797416/KingTrrustTestiomonial1_ifhr0g.mp4'
    },
]

export default videoSrc;