import { lazy, Suspense } from 'react';
import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Navbar from './components/navbar/nav';
import Footer from './components/footer/footer';
import Home from './components/home/home';
import Cookie from './components/cookie/cookie';
import { HelmetProvider } from 'react-helmet-async';
const About = lazy(() => import('./components/about/about'));
const Blog = lazy(() => import('./components/pageNotFound/pageNotFound'))
const Covid = lazy(() => import('./components/covid/covid'))
const Booking = lazy(() => import('./components/booking/booking'))
const Contact = lazy(() => import('./components/contact/contact'))
const Services = lazy(() => import('./components/services/services'))

const App = () => {
  const [backToTopButton, setbackToTopButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 800) {
        setbackToTopButton(true);
      } else {
        setbackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const helmetContext = {};

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <Navbar />
          <ScrollToTop />
          <main>
            <Suspense fallback={<h1 style={{ fontSize: '1rem' }}>Loading...</h1>}>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/services' element={<Services />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/booking' element={<Booking />} />
                <Route path='/covid19' element={<Covid />} />
                <Route path='/blog' element={<Blog />} />
                {/* <Route path='/*' element={<PageNotFound />} /> */}
              </Routes>
            </Suspense>
          </main>
          <Footer />
          {backToTopButton && (
            <button onClick={scrollUp} className="top-scroll">
              &uarr;
            </button>
          )}
          <Cookie />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
