import { useState, useEffect } from "react";
import "./cookie.css";

export default function Cookie() {
  const [showCookie, setShowCookie] = useState(
    JSON.parse(localStorage.getItem("MY_APP_STATE") || true)
  );

  useEffect(() => {
    localStorage.setItem("MY_APP_STATE", JSON.stringify(showCookie));
  }, [showCookie]);

  useEffect(() => {
    const data = localStorage.getItem("MY_APP_STATE");
    if (data !== null) setShowCookie(JSON.parse(data));
  }, [showCookie]);

  return (
    <>
      {showCookie && (
        <section className="cookie-container">
          <div className="cookies-consent">
            <div>
              <p>
                We use cookies to enhance your browsing experience. By clicking
                "Accept All" you consent to our use of cookies
              </p>
              <div className="cookie-btn">
                <button onClick={() => setShowCookie(!showCookie)}>
                  Accept All
                </button>
                {/* <button>Decline</button> */}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
