import phoneicon from "../home-images/phone-icon.svg";
import emailicon from "../home-images/email-icon.svg";
import "./slider.css";
import data from "./data";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = data.length;

  const autoScroll = true;
  let slideInterval;
  const intervalTime = 10000;

  // const prevSlide = () => {
  //   setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  //   console.log("prev");
  // };
  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const auto = () => {
    slideInterval = setInterval(nextSlide, intervalTime);
  };
  const moveDot = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide, autoScroll, slideInterval, auto]);

  return (
    <>
      <section className="slideshow">
        {data.map((slide, index) => {
          return (
            <section
              key={index}
              className={index === currentSlide ? "slide current" : "slide"}
            >
              {index === currentSlide && (
                <>
                  <img src={slide.url} alt="slider" className="slider-img" />
                  {/* <img src={slide.fadebg} alt="bg" className="fade-bg" /> */}
                  <div className="slide-overlay"></div>
                  <div className="content">
                    <div className="home__welcome">
                      <h1>{slide.heading}</h1>
                      <p>{slide.paragraph}</p>
                      <Link to="/booking">
                        <button>{slide.button}</button>
                      </Link>
                    </div>
                    <div className="intro__contact">
                      <a href="tel:+2349160000381">
                        <img src={phoneicon} alt="phone" />
                      </a>
                      <a href="mailto:admin@kingtrustmedical.com">
                        <img src={emailicon} alt="email" />
                      </a>
                    </div>
                  </div>
                </>
              )}
            </section>
          );
        })}
        <div className="active-btn">
          {Array.from({ length: slideLength }).map((data, index) => (
            <button
              key={index}
              onClick={() => moveDot(index + 0)}
              className={currentSlide === index + 0 ? "active-dot" : ""}
            ></button>
          ))}
        </div>
      </section>
    </>
  );
};

export default Slider;
